import '@fontsource/silkscreen/400.css';

import React from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { graphql, Link } from 'gatsby';
import { useLocation, WindowLocation } from '@reach/router';

import Deindex from '../../components/Deindex';

const BACKGROUND_GRADIENT = `
  repeating-linear-gradient(
    45deg,
    #3e00f5,
    #3e00f5 448px,
    #5e00f7 448px,
    #5e00f7 752px
  )
`;
const UNDERLINE_GRADIENT = `
  linear-gradient(253.86deg, #FF45A9 26.69%, #FFAD0D 66.94%)
`;

const TITLE = `TRY IT YOURSELF`;
const CAPTION = `Choose a path and learn along the way!`;
const SUMMARY = `
  Each decision leads to a different path.
  Based on what you learned from the previous video, try to choose the path that follows design thinking methodology.
  Don't worry about making mistakes, that's how you learn! Good luck!
`;
const BACK_CTA_LABEL = 'Back to video';
const FORWARD_CTA_LABEL = `I'm done playing!`;

const ACTIVITY_SRC =
  'https://ironhack-edu.github.io/short-courses/ux/short-course.html';

const Page = (): React.ReactElement => {
  const { state } = useLocation() as WindowLocation<{
    lead: { email: string };
  }>;

  return (
    <Flex
      sx={{
        bg: BACKGROUND_GRADIENT,
        flexDir: 'column',
        minH: '100vh',
        w: '100%',
      }}
    >
      <Deindex />
      <Center
        sx={{
          flexDir: 'column',
          flexGrow: 1,
          maxW: '1440px',
          mx: 'auto',
          p: [1, null, 3],
          w: 'inherit',
        }}
      >
        <Container
          as={Stack}
          spacing={4}
          sx={{ color: 'white', maxW: '920px', p: 0, textAlign: 'center' }}
        >
          <Text
            color="white"
            fontFamily="Silkscreen"
            textShadow="0px 4px 0px #EC398A"
            textStyle="title1"
          >
            {TITLE}
          </Text>
          <AspectRatio ratio={[2 / 4, null, 16 / 9]}>
            <Box
              as="iframe"
              src={ACTIVITY_SRC}
              sx={{
                borderRadius: 'md',
                borderWidth: '3px',
                borderColor: '#F8A324',
              }}
              title="UX Short Course Activity"
            />
          </AspectRatio>
          <Text fontWeight="bold" textStyle="body4">
            {CAPTION}
          </Text>
          <Text sx={{ alignSelf: 'center', maxW: '70ch' }} textStyle="body5">
            {SUMMARY}
          </Text>
        </Container>
        <Stack direction={['column', null, 'row']} mt={4} spacing={2} w="100%">
          <Button
            as={Link}
            leftIcon={<ArrowBackIcon />}
            state={state}
            sx={{
              bg: 'white',
              w: ['100%', null, 'auto'],
            }}
            to={'/en/ux-shortcourse-step-1'}
            variant="outline"
          >
            {BACK_CTA_LABEL}
          </Button>
          <Spacer />
          <Button
            as={Link}
            rightIcon={<ArrowForwardIcon />}
            state={state}
            to={`/en/ux-shortcourse-step-3`}
            w={['100%', null, 'auto']}
          >
            {FORWARD_CTA_LABEL}
          </Button>
        </Stack>
      </Center>
      <Box sx={{ h: '20px', w: '100%', bg: UNDERLINE_GRADIENT }} />
    </Flex>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: { lng: { eq: "en" }, ns: { regex: "/(seo)/" } }
    ) {
      ...TranslationFragment
    }
  }
`;

export default Page;
